import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header__inner" style={{ top: "0" }}>
        <div className="wrp-navi">
          <div className="fixed_txt_img">
            <img
              width="188"
              height="32"
              src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/bstack-logo-global.svg"
              alt="mockitt header"
            />
          </div>
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                Privacy
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="about_box">
        <div className="about_box_top">
          <div className="about_box_top_inner">
            <div className="about_box_top_inner_body">
              <h1>
                Our vision is to be the testing infrastructure for the internet
              </h1>
              <p>
                BrowserStack powers today’s digital economy to build quality and
                bug-free software at speed—the same software that’s changing the
                world.
              </p>
            </div>
          </div>
        </div>

        <div className="about1_box">
          <div className="about1_box_inner">
            <div className="about1_body">
              <div className="about1_body_inner">
                <div class="info_indicator__heading ">6 million</div>
                <div class="info_indicator__desc">developer sign ups</div>
              </div>
            </div>

            <div className="about1_body">
              <div className="about1_body_inner">
                <div class="info_indicator__heading ">1 billion</div>
                <div class="info_indicator__desc">tests per year</div>
              </div>
            </div>
            <div className="about1_body">
              <div className="about1_body_inner">
                <div class="info_indicator__heading ">19 global</div>
                <div class="info_indicator__desc">data centers</div>
              </div>
            </div>
            <div className="about1_body">
              <div className="about1_body_inner">
                <div class="info_indicator__heading ">1000+</div>
                <div class="info_indicator__desc">employees</div>
              </div>
            </div>
            <div className="about1_body">
              <div className="about1_body_inner">
                <div class="info_indicator__heading ">3000+ real</div>
                <div class="info_indicator__desc">devices and browsers</div>
              </div>
            </div>
          </div>
        </div>

        <div className="about2_box">
          <h3>Trusted by more than 50,000 customers globally</h3>
          <div className="about2_box_img">
            <img
              alt=""
              src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/vc/brands/brands-dark-wp-xl.svg"
            />
          </div>
        </div>

        <div className="about3_box">
          <div className="about3_box_inner_top">
            <div className="about3_top_body">
              <h2>What we do</h2>
              <div className="about3_top_flex">
                <div className="about3_internal">
                  <p>
                    <strong>
                      Testing in the DevOps cycle is getting increasingly
                      complex
                    </strong>
                    Currently, there are 4 billion active users accessing the
                    internet through 9,000 distinct devices, 21 different
                    operating systems, and 8 major browser engines. Companies,
                    both big and small need to ensure their websites and
                    applications render as intended so customers like you have a
                    good user experience, regardless of the device you view it
                    in.
                  </p>
                </div>
                <div className="about3_internal">
                  <p>
                    <strong>
                      BrowserStack’s cloud platform helps remove that complexity
                    </strong>
                    BrowserStack provides developer teams instant access to a
                    cloud platform that allows them to comprehensively test
                    their websites and mobile applications for functionality,
                    performance, and visual appeal so they can release bug-free
                    software faster and at scale.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about3_box_inner_bot">
            <div className="about3_box_inner_bot_inner">
              <div className="about3_internal about3_bot_top">
                <div className="about3_bot_body">
                  <h5>Test your websites</h5>
                  <div className="about3_bot_flex">
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/Live.svg"
                            data-lazy-type="image"
                            data-id="68792"
                            title="Live"
                            alt="Live"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">Live</h4>
                          <div class="product-card-box-desc">
                            Manual cross browser testing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/Automate.svg"
                            data-lazy-type="image"
                            data-id="68790"
                            title="Automate"
                            alt="Automate"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">Automate</h4>
                          <div class="product-card-box-desc">
                            Browser automation grid
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/Percy.svg"
                            data-lazy-type="image"
                            data-id="68795"
                            title="Percy"
                            alt="Percy"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">Percy</h4>
                          <div class="product-card-box-desc">
                            Visual testing and review
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/accessibility.svg"
                            data-lazy-type="image"
                            data-id="68785"
                            title="accessibility"
                            alt="accessibility"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Accessibility Testing
                          </h4>
                          <div class="product-card-box-desc">
                            Find and report issues
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2024/06/Automate-Turboscale.svg"
                            data-lazy-type="image"
                            data-id="94293"
                            title="Automate Turboscale"
                            alt=""
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Automate Turboscale
                          </h4>
                          <div class="product-card-box-desc">
                            Self-managed Automation Grid
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2024/06/Accessibility-Automation.svg"
                            data-lazy-type="image"
                            data-id="94301"
                            title="Accessibility Automation"
                            alt=""
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Accessibility Automation
                          </h4>
                          <div class="product-card-box-desc">
                            Automate WCAG &amp; ADA testing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2024/06/Low-Code-Automation.svg"
                            data-lazy-type="image"
                            data-id="94300"
                            title="Low Code Automation"
                            alt=""
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Low Code Automation
                          </h4>
                          <div class="product-card-box-desc">
                            Automation without coding
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="about3_bot_body">
                  <h5>Test your mobile apps</h5>
                  <div className="about3_bot_flex">
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/App-Live.svg"
                            data-lazy-type="image"
                            data-id="68788"
                            title="App Live"
                            alt="App Live"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">App Live</h4>
                          <div class="product-card-box-desc">
                            Manual real device testing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/App-Automate.svg"
                            data-lazy-type="image"
                            data-id="68787"
                            title="App Automate"
                            alt="App Automate"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">App Automate</h4>
                          <div class="product-card-box-desc">
                            Real device automation cloud
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/App-Percy.svg"
                            data-lazy-type="image"
                            data-id="68789"
                            title="App Percy"
                            alt="App Percy"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">App Percy</h4>
                          <div class="product-card-box-desc">
                            Visual testing and review
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2024/06/App-Accessibility-Testing.svg"
                            data-lazy-type="image"
                            data-id="94299"
                            title="App Accessibility Testing"
                            alt=""
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            App Accessibility Testing
                          </h4>
                          <div class="product-card-box-desc">
                            Test WCAG compliance
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="about3_bot_body">
                  <h5>Test management & optimization</h5>
                  <div className="about3_bot_flex">
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/06/tcm.svg"
                            data-lazy-type="image"
                            data-id="66621"
                            title="Test Management"
                            alt="Test Management"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Test Management
                          </h4>
                          <div class="product-card-box-desc">
                            Unify &amp; track all test cases
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about3_body_flex">
                      <div className="about3_son">
                        <div class="product-card-box-icon">
                          <img
                            loading="lazy"
                            decoding="async"
                            class="b-lazy product-card-box-img b-lazy-loaded"
                            aria-hidden="true"
                            focusable="false"
                            src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/07/Observability.svg"
                            data-lazy-type="image"
                            data-id="68794"
                            title="Observability"
                            alt="Observability"
                            height="24"
                            width="24"
                          />
                        </div>
                        <div class="product-card-box-wrap">
                          <h4 class="product-card-box-title">
                            Test Observability
                          </h4>
                          <div class="product-card-box-desc">
                            Test reporting &amp; debugging
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about4_box">
          <div className="about4_box_inner">
            <h2>Our journey so far</h2>
            <div class="about3_top_flex">
              <div class="about3_internal">
                <p>
                  It started in 2011 when Ritesh Arora and Nakul Aggarwal set
                  out to launch a website for their tech consulting business.
                  Nakul built the website in two days, but when Ritesh had to
                  test it, things got out of hand. The website had to be tested
                  for compatibility across different devices, operating systems,
                  and browsers.
                </p>
              </div>
              <div class="about3_internal">
                <p>
                  It was a tedious process and it was slowing them down, as
                  always. They decided to take a quick look at the market to
                  understand the problem better, and they found out that they
                  were not alone. The struggle with cross-compatibility testing
                  was universal. This realization gave birth to BrowserStack.
                </p>
              </div>
            </div>

            <div className="about4_flex">
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Screenshot-2021-10-20-at-5.57-1-4.png"
                />
                <div class="card-b-3__title">
                  Hear BrowserStack’s founding story
                </div>
              </div>
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Untitled-design-2-1-2.png"
                />
                <div class="card-b-3__title">
                  Ritesh shares learnings from his entrepreneurial journey
                </div>
              </div>
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Video-thumbnail-2x-post-event-landing-page-1.png"
                />
                <div class="card-b-3__title">
                  Watch Nakul discuss BrowserStack’s growth, blitzscaling
                  journey, and the road ahead
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about5_box">
          <div className="about5_box_inner">
            <h2>Meet our founders</h2>
            <div className="about5_box_flex">
              <div className="about5_flex_body">
                <div className="about5_internal">
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/ritesh.jpg"
                  />
                  <div
                    class="card-b-4__designation"
                    id="card-cta-designation2e846e"
                  >
                    Co-founder &amp; CEO
                  </div>
                  <div id="card-cta-name2e846e" class="card-b-4__name">
                    Ritesh Arora
                  </div>
                  <button>Read bio</button>
                </div>
                <div className="about5_internal">
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Untitled-design-7-1-1-1-1.png"
                  />
                  <div
                    class="card-b-4__designation"
                    id="card-cta-designation2e846e"
                  >
                    Co-founder & CTO
                  </div>
                  <div id="card-cta-name2e846e" class="card-b-4__name">
                    Nakul Aggarwal
                  </div>
                  <button>Read bio</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about6_box">
          <div className="about6_box_inner">
            <h2>Meet our leaders</h2>
            <div className="about5_box_flex">
              <div className="about5_flex_body" style={{ width: "100%" }}>
                <div
                  className="about5_internal"
                  style={{ width: "33.33333%", minHeight: "auto" }}
                >
                  <img
                    alt=""
                    src="	https://browserstack.wpenginepowered.com/wp-content/uploads/2023/08/vikas-surekha.png"
                  />
                  <div
                    class="card-b-4__designation"
                    id="card-cta-designation2e846e"
                  >
                    SVP, Finance
                  </div>
                  <div id="card-cta-name2ead45" class="card-b-4__name">
                    Vikash Sureka
                  </div>
                  <Link to="/about" class="card-b-4__quote-social-link">
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/vc/card_block/LinkedIn-icon-blue.svg"
                      data-lazy-type="image"
                      data-id=""
                      alt="Vikash Sureka Social Link"
                      class="b-lazy card-b-4__quote-social b-lazy-loaded"
                      width="28"
                      height="28"
                    />
                  </Link>
                </div>
                <div
                  className="about5_internal"
                  style={{ width: "33.33333%", minHeight: "auto" }}
                >
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Untitled-design-6-min.png"
                  />
                  <div
                    class="card-b-4__designation"
                    id="card-cta-designation2e846e"
                  >
                    EVP, Global Sales
                  </div>
                  <div id="card-cta-name2ead45" class="card-b-4__name">
                    Sunil Jose
                  </div>
                  <Link to="/about" class="card-b-4__quote-social-link">
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/vc/card_block/LinkedIn-icon-blue.svg"
                      data-lazy-type="image"
                      data-id=""
                      alt="Vikash Sureka Social Link"
                      class="b-lazy card-b-4__quote-social b-lazy-loaded"
                      width="28"
                      height="28"
                    />
                  </Link>
                </div>
                <div
                  className="about5_internal"
                  style={{ width: "33.33333%", minHeight: "auto" }}
                >
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Niranjan@2x-1.jpg"
                  />
                  <div
                    class="card-b-4__designation"
                    id="card-cta-designation2e846e"
                  >
                    VP, Business Ops & Strategy
                  </div>
                  <div id="card-cta-name2ead45" class="card-b-4__name">
                    Niranjan Chavan
                  </div>
                  <Link to="/about" class="card-b-4__quote-social-link">
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/vc/card_block/LinkedIn-icon-blue.svg"
                      data-lazy-type="image"
                      data-id=""
                      alt="Vikash Sureka Social Link"
                      class="b-lazy card-b-4__quote-social b-lazy-loaded"
                      width="28"
                      height="28"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about7_box">
          <div className="about7_box_inner">
            <div className="about7_box_inner_body">
              <h2>Backed by</h2>
              <div className="about7_flex">
                <div className="about7_internal">
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/accel-06-1.png"
                  />
                </div>
                <div className="about7_internal">
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Bond-1.png"
                  />
                </div>
                <div className="about7_internal">
                  <img
                    alt=""
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/image-8-4.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about8_box">
          <div className="about8_box_inner">
            <h2 className="public_h2">We love our community</h2>
            <div className="about8_flex">
              <div className="about8_flex_inner">
                <div className="about8_body">
                  <img
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/03/Open-source-1.png"
                    alt="Open source - BrowserStack community"
                    width="720"
                    height="320"
                  />
                  <div
                    class="carousel-cards-heading"
                    title="Open Source Program"
                  >
                    Open Source Program
                  </div>
                  <div
                    class="carousel-cards-desc"
                    title="You move new ideas forward and make software better for everyone. Get lifetime access to test your open source project on 3000+ browsers and real mobile devices."
                  >
                    You move new ideas forward and make software better for
                    everyone. Get lifetime access to test your open source
                    project on 3000+ browsers and real mobile devices.
                  </div>
                </div>
                <div className="about8_body">
                  <img
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Breakpoint-1-1-1.png"
                    alt="Open source - BrowserStack community"
                    width="720"
                    height="320"
                  />
                  <div
                    class="carousel-cards-heading"
                    title="Open Source Program"
                  >
                    Events
                  </div>
                  <div
                    class="carousel-cards-desc"
                    title="You move new ideas forward and make software better for everyone. Get lifetime access to test your open source project on 3000+ browsers and real mobile devices."
                  >
                    Webinars and conferences to help developers interact with
                    industry leaders, stay in the know about testing, and
                    network with the community.
                  </div>
                </div>
                <div className="about8_body">
                  <img
                    src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Test-University-0-1.png"
                    alt="Open source - BrowserStack community"
                    width="720"
                    height="320"
                  />
                  <div
                    class="carousel-cards-heading"
                    title="Open Source Program"
                  >
                    Test University
                  </div>
                  <div
                    class="carousel-cards-desc"
                    title="You move new ideas forward and make software better for everyone. Get lifetime access to test your open source project on 3000+ browsers and real mobile devices."
                  >
                    Online learning platform for developers and partners
                    interested in learning about testing with BrowserStack, and
                    software testing in general.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about9_box">
          <div className="about8_box_inner">
            <h2 className="public_h2">Who we are</h2>
            <div className="about9_flex">
              <div className="about9_flex_left">
                <p>
                  At BrowserStack we’re building the testing infrastructure for
                  the world. We’re extremely curious, passionate, and
                  customer-obsessed about our mission of empowering developers
                  to build amazing experiences.
                </p>
                <p>
                  We know we’ll grow only when our people grow—so we build teams
                  that are open, transparent, collaborative, and above all
                  supportive.
                </p>
                <p>
                  We’re remote-first and our people have the choice to work from
                  anywhere—it’s our core values that bring us all together and
                  drive everything we do.
                </p>
                <p>
                  We’re thinkers, movers, innovators, and doers who believe no
                  feat is too big to achieve. We strive to deliver awesome
                  results, fast; and in that way, we’re a lot like you.
                </p>
              </div>
              <div className="about9_flex_right">
                <img
                  width="560"
                  height="415"
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Frame-17-3-1.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="about9_box">
          <div className="about8_box_inner">
            <h2 className="public_h2">Our values</h2>
            <div className="about10_flex">
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Solve-real-problems.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Solve real problems
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Build-trust-and-collab-1.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Build trust and collaborate
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Customer-Obsession.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Customer obsession
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Deep-Dive.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Deep dive
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Be-open-and-respectful.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Be open and respectful
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Believe-in-simple.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Believe in simple
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Speed-Matters.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Speed matters
                </div>
              </div>
              <div className="about10_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/02/Take-logical-and-analytical.png"
                />
                <div class="carousel-rows-heading" title="Solve real problems">
                  Take logical and analytical decisions
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about11_box">
          <div className="about11_box_inner">
            <h2>BrowserStack In The News</h2>
            <div className="about11_flex">
              <div className="about11_body">
                <img
                  alt=""
                  src="	https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Rectangle-152-3.png"
                />
              </div>
              <div className="about11_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/image-4-3.png"
                />
              </div>
              <div className="about11_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/image-5-3.png"
                />
              </div>
              <div className="about11_body">
                <img
                  alt=""
                  src="	https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/Rectangle-155-4.png"
                />
              </div>
              <div className="about11_body">
                <img
                  alt=""
                  src="	https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/image-6-3.png"
                />
              </div>
              <div className="about11_body">
                <img
                  alt=""
                  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2022/04/image-7-3.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="about12_box">
          <div className="about12_box_inner">
            <h2>Learn more about BrowserStack</h2>
            <div className="about12_flex">
              <div className="about12_body">
                <div className="about12_internal">
                  <div class="card-b-2__tag">Blog </div>
                  <div class="card-b-2__desc">
                    Read about the latest innovations and trends in testing,
                    product updates, and community articles.
                  </div>
                </div>
              </div>
              <div className="about12_body">
                <div className="about12_internal">
                  <div class="card-b-2__tag">Press Room </div>
                  <div class="card-b-2__desc">
                    Get updated company news and announcements, press mentions,
                    and access our media kit.
                  </div>
                </div>
              </div>
              <div className="about12_body">
                <div className="about12_internal">
                  <div class="card-b-2__tag">Become a BrowserStack Partner</div>
                  <div class="card-b-2__desc">
                    Grow your business and provide exceptional service to your
                    clients by partnering with us.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
