import React from "react";
import Footer from "./Footer";
import "../css/message.css";
import Header from "./Header";

function Message() {
  return (
    <div className="main-container">
      <Header />
      <div className="msg_box">
        <div className="msg1_box">
          <div className="msg1_box_inner">
            <h1>
              <span className="msg1_one_span">CEO MESSAGE</span>
              <span className="msg1_two_span">
                <img
                  src="https://relace.co.jp/images/app/globals/ceo-message_pc.svg"
                  alt="IT活用により、人と人、仕事と仕事を繋ぎ、手を携える結び目として"
                />
              </span>
              <span className="msg1_three_span">
                <span>株式会社リレイス / 代表取締役</span>
                <span>石田 宏実</span>
              </span>
            </h1>
          </div>
        </div>

        <div className="msg2_box">
          <div>
            <div className="msg2_first_div">
              <div className="msg2_first_flex">
                <div className="msg_first_one">
                  <h2>
                    <span className="msg2_first_span">
                      <span>MESSAGE 001</span>
                      <span>
                        日々進化するIT技術を取り入れ、合理的な判断と応用力を武器にDXを実現します。
                      </span>
                    </span>
                  </h2>
                  <p>
                    DX（デジタル変革）の実現が求められる昨今、企業が競争力を持ち、働く質を高めた上で勝ち残るには、単に業務をIT化してシステムを導入するだけでは十分と言えません。
                    <br />
                    しかし実際には、業務を理解せずにITシステムが開発されたり、完成したITシステムを従業者が使いこなせていなかったりと、DXの実現を図ろうとしながら失敗に終わっているケースが多く存在しています。
                  </p>
                </div>
                <div className="msg_first_two">
                  <img
                    width="559"
                    height="374"
                    src="https://relace.co.jp/images/app/message/pic-01.jpg"
                    alt=""
                    class="w-full"
                  />
                </div>
              </div>
              <p className="msg2_relax">
                <strong>
                  DXを実現するために大切なこと。それは、現状維持のしがらみを乗り越え、変化のリスクを取ること
                </strong>
                だと思います。
                <br />
                とはいえ、リスクはなるべく抑えたいところです。リスクを最小限にするために最も重要なのが、パートナーとなるIT企業選びです。
                <br />
                <strong>
                  私たちリレイスは、システム開発だけを行うIT企業ではありません
                </strong>
                。DX時代のITシステムの在り方を見据え、ITマネジメントからサービス設計、業務分析、マーケティング、デザイン、ディレクション、エンジニアリング、ITインフラ、情報セキュリティ、運用サポート対応など、
                <strong>
                  様々な角度から総合的にITソリューションを提供しています
                </strong>
                。<br />
                <br />
                リレイスに所属するエンジニアは、自らの能力を現実社会の人や仕事のために活用しようと考える高い志を持ったメンバーばかりです。
                <br />
                仕事のやりがいと面白さを知った上で集まったメンバーが、日々見識をアップデートしながら問題提起及び問題解決に取り組むことで、プロジェクト全体を理想的に進行することができるのです。
                <br />
                <br />
                私たちの提供した技術やサービスで、お客様のサービスや業務がより良くなり、さらなる発展や次なる可能性に繋がるきっかけとなったときが一番やりがいを感じる瞬間です。
                <br />
                IT技術の進化は目覚ましく、明日は今日より進んでいます。これからも私たちは、
                <strong>
                  時間や予算の限られた状況の中で、日々進化する技術を取り入れて下す合理的な判断と応用力を武器に、デジタル化の力を実現
                </strong>
                していきます。
              </p>

              <div className="msg2_first_flex" style={{ marginTop: "8rem" }}>
                <div className="msg_two_two">
                  <img
                    width="559"
                    height="374"
                    src="https://relace.co.jp/images/app/message/pic-01.jpg"
                    alt=""
                    class="w-full"
                  />
                </div>
                <div className="msg_first_one">
                  <h2>
                    <span className="msg2_first_span">
                      <span>MESSAGE 002</span>
                      <span>
                        リレイスの事業の第一の目的は、お客様のプロジェクトの成功です。{" "}
                      </span>
                    </span>
                  </h2>
                  <p>
                    私たちは、量と安さを売りにしていません。そのため、他社と比較された際に見積額が高いと感じられるかもしれません。{" "}
                    <br />
                    満足いくシステムを安く作れるのであればそれに越したことはありませんが、そういったケースは稀と言えます。{" "}
                    <br />
                    実際リレイスにも、多機能にしすぎて中核機能が賄えず価値の低いシステムになったケースや、バグが多発し使えないので作り直して欲しいというご相談が少なからず寄せられています。
                  </p>
                </div>
              </div>
              <p className="msg_relax">
                スピード感・応用力が求められる近年では、システム開発プロジェクトが結果を出せるのは3割程度と言われています。せっかく開発したシステムも、結果を出せずに再開発することになってしまった場合、コストが高くついてしまいかねません。それを回避するためには、協議や作業の目的を明確にし、本当に必要なものを優先度をつけて見極めなければなりません。
                <br />
                <br />
                <strong>
                  リレイスでは、目先の案件獲得を優先するのではなく、お客様のプロジェクトの成功を第一の目的
                </strong>
                としております。
                <br />
                開発着手させていただく案件は、これまで多くのお客様に満足頂き培ったプロジェクト進行力を元に、全力でサポートさせていただきますので、私たちの強みを理解し共感いただけるお客様に選んでいただきたいと思っております。
                <br />
                開発のご相談は、大小様々な案件についていただいております。
                <strong>
                  システム化のアイデアを形にする初期の段階でも親身にサポートさせていただきます
                </strong>
                ので、まずは一歩踏み出してご相談ください。
                <br />
                <br />
                開発相談後、チームリーダーや決裁者様の決断でリレイスをお選びいただき、良好な関係の上でプロジェクトを進行し着々と結果を積み上げていくことで、多くの企業とその後も継続的にお取引させていただいております。
                <br />
                継続してお取引いただくことで、お客様の仕事や事業の将来像を理解したITパートナーとしてより最適化することができます。その結果、
                <strong>
                  事業計画を素早く具体化、言語化して実行することができ、お客様にとってなくてはならない存在として価値を発揮できている
                </strong>
                と自負しております。
                <br />
                <br />
                私たちの業務領域の中心はITシステムの基礎及び応用ですが、サービス例として掲載している内容以外の案件もご相談いただいております。
                <br />
                事業で課題や不足を感じている場合、ぜひリレイスへお気軽にご相談ください。課題を元に、どのような計画、PoC(概念実証)、解決策等を立案できるか実現可能な方法を模索いたします。
                <br />
                <br />
                ITシステム業は、仕事を通じて幅広い業種のお客様と関わることができる業界です。
                <br />
                多様なお客様と関わることで、見識や教養が広がり、正すきっかけともなり、天職として力を発揮することができています。
                <br />
                IT活用により、人と人、仕事と仕事を繋ぎ、手を携える結び目として、また、困難な時代に立ち向かうためのビジネスの友の一人として、お客様の描く未来の実現をサポートさせていただければ幸いです。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="main10_box">
        <div className="main10_box_inner">
          <p className="main10_first_p">
            WE WILL DO YOUR BEST TO SUPPORT YOUR CHALLENGES
          </p>
          <h3>
            お客さまの新たな挑戦を
            <br />
            全力でサポートいたします
          </h3>
          <p className="main10_sec_p">
            お客さまに伴走するパートナーとして、ご期待に本気でお応えいたします。
            <br />
            ぜひお気軽にお問い合わせください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Message;
