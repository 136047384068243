import React from "react";
import "../css/paysuccess.css";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Success = () => {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="header__inner" style={{ top: "0" }}>
        <div className="wrp-navi">
          <div className="fixed_txt_img">
            <img
              width="188"
              height="32"
              src="https://browserstack.wpenginepowered.com/wp-content/themes/browserstack/img/bstack-logo-global.svg"
              alt="mockitt header"
            />
          </div>
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                Privacy
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="successDiv">
        <div className="success-container">
          <div className="modal-content">
            <div className="checkmark-circle">
              <FontAwesomeIcon icon={faCheck} />
              <div className="checkmark"></div>
            </div>
            <p>Pay Success</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Success;
