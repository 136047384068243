import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>
            <span className="privacy_inner_h1_span">
              <span>PRIVACY POLICY</span>
              <span>
                {" "}
                <font _mstmutation="1">個人情報保護について</font>
              </span>
            </span>
          </h1>
          <div className="privacy_laptop">
            <p>
              株式会社リレイス（以下「当社」といいます）では、以下の通り個人情報保護方針を定め、個人情報の重要性を認識し、適切に保護することが社会的責務であると考え、個人情報の保護に関する法律及び関係法令を遵守するとともに、安全対策を適切に講じ保護・管理します。当社で取得した個人情報は利用目的の範囲内で適切な取り扱い、運用に努めてまいります。
            </p>
          </div>

          <div className="privacy_body_internal">
            <dl>
              <dt>個人情報の利用目的</dt>
              <dd>
                当社サービスに関する個人情報使用目的は下記となります。
                <br />
                お客さまへのサービスの提供とそれらに附帯する諸対応のためアンケートやキャンペーンの実施のためマーケティングデータの調査、統計、分析のためメンテナンス，重要なお知らせなど必要に応じたご連絡のため新サービス、新機能の開発、システムの維持、不具合対応のため
              </dd>
            </dl>
            <dl>
              <dt>個人情報の取得に関して</dt>
              <dd>
                お客様の個人情報を取得する際には、あらかじめ取得目的を明示した上で、必要最小限の個人情報を適法・適正な方法で行います。個人情報の管理と安全対策に関して取得した個人情報は、個人情報管理責任者が厳重に管理・監督します。また、個人情報への不正アクセス・紛失・破損・改ざん・漏洩等を防止するため、セキュリティシステムの維持・管理体制の整備、社員教育等の必要とされる対策を適時講じていき、個人情報の取扱いについて十分な注意を払い管理を行ないます。{" "}
              </dd>
            </dl>
            <dl>
              <dt>お問い合わせフォーム</dt>
              <dd>
                当社は、お問い合わせフォームにて、名前やメールアドレス等の個人情報を入力いただいております。取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどでご連絡する場合のみに利用させていただくものであり、これら目的以外では利用いたしません。{" "}
              </dd>
            </dl>
            <dl>
              <dt>ご本人の照会</dt>
              <dd>
                お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。{" "}
              </dd>
            </dl>
            <dl>
              <dt>法令遵守について</dt>
              <dd>
                個人情報の管理については、定められた法令を遵守してまいります。将来的に法令の改正があった場合は、その内容にあわせて上記の項目は修正していきます。{" "}
              </dd>
            </dl>
            <dl>
              <dt>Cookieについて</dt>
              <dd>
                当サイトでは、より快適にご利用して頂くために、サイトの一部でクッキー
                (Cookie)を使用しております。匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。{" "}
              </dd>
            </dl>
            <dl>
              <dt>他サイトからの埋め込みコンテンツ</dt>
              <dd>
                当サイトの投稿には埋め込みコンテンツ (動画、画像、投稿など)
                が含まれます。
                <br />
                これらは、データの収集、Cookie
                の使用、サードパーティによる追加トラッキングの埋め込み、埋め込みコンテンツとのやりとりの監視を行うことがあります。アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。{" "}
              </dd>
            </dl>
            <dl>
              <dt>アクセス解析</dt>
              <dd>
                当サイトは、アクセス解析ツール「Googleアナリティクス」を利用しています。Googleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定することはできません。{" "}
              </dd>
            </dl>
            <dl>
              <dt>WEB上で個人情報を登録頂く際のセキュリティー対策について</dt>
              <dd>
                当サイトに個人情報を登録頂く際には、お客さまの情報を保護するためにＳＳＬを使用しインターネット上で送信されるデータを暗号化し、セキュリティーの維持に努めております。{" "}
              </dd>
            </dl>
            <dl>
              <dt>お問い合わせ先</dt>
              <dd>
                当社の個人情報の取扱いに関してお問い合わせがある場合は、お問い合わせページよりご連絡ください。合理的な範囲で、速やかに対処いたします。
              </dd>
            </dl>

            <p className="contact_base">
              2016年2月1日　制定
              <br />
              2023年12月1日　改定
              <br />
              <br />
              株式会社リレイス代表取締役
              <br />
              石田 宏実
            </p>
          </div>
        </div>
      </div>
      <div className="main10_box">
        <div className="main10_box_inner">
          <p className="main10_first_p">
            WE WILL DO YOUR BEST TO SUPPORT YOUR CHALLENGES
          </p>
          <h3>
            お客さまの新たな挑戦を
            <br />
            全力でサポートいたします
          </h3>
          <p className="main10_sec_p">
            お客さまに伴走するパートナーとして、ご期待に本気でお応えいたします。
            <br />
            ぜひお気軽にお問い合わせください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
