import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const faq = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887t.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75t-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1t-.375-.888t.375-.887z"
      ></path>
    </svg>
  );
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <div className="transparent-div-left">
            <p className="main1_left_first_p">
              <span style={{ marginBottom: ".25rem" }}>
                お客さまの”新たな挑戦”の実現に向けて、
              </span>
              <span>私たちは全力でサポートします</span>
            </p>
            <h1>
              DXを成功に導く
              <br />
              伴走型ITパートナー
            </h1>
            <p className="main1_left_sec_p">
              要求定義から開発進行・完成・公開まで一気通貫で対応可能。
              <br />
              「何から手をつければいいかわからない」
              <br />
              そんな方もご安心。豊富な開発経験を元に、卓越した課題整理力と専門性の高いエンジニアリングで、プロジェクトを成功に導きます。
            </p>
            <ul>
              <li>#新規事業開発</li>
              <li>#システム開発</li>
              <li>#運用支援</li>
              <li>#ITコンサル</li>
            </ul>
            <Link to="/contact" onClick={toTop}>
              <span>まずは無料相談する</span>
            </Link>
          </div>

          <div className="transparent-div-right">
            <img
              width="650"
              height="707"
              src="https://relace.co.jp/images/app/home/mv.png"
              alt=""
              class="object-cover tablet:object-contain  w-full tablet:h-[540px] laptop:h-[600px] desktop:h-[707px]"
            />
          </div>
        </div>
      </div>

      <div className="main2_box">
        <div className="main2_box_inner">
          <h2>
            <span className="main2_inner_h2_first_span">
              <span>OWNERS</span>
              <span>弊社が開発サポートしている企業</span>
            </span>
          </h2>
          <p className="main2_first_p">※ 掲載企業は一部となります</p>
          <ul>
            <li>
              <img
                src="https://relace.co.jp/images/app/case-study/and-resilience/logo.svg"
                alt="アンドレジリエンスのロゴ"
              />
            </li>
            <li>
              <img
                src="https://relace.co.jp/images/app/case-study/daicolo/logo.svg"
                alt="ダイコロ株式会社のロゴ"
              />
            </li>
            <li>
              <img
                src="https://relace.co.jp/images/app/case-study/mitsui-fudosan/logo.svg"
                alt="三井不動産のロゴ"
              />
            </li>
            <li>
              <img
                src="https://relace.co.jp/images/app/case-study/ja-rokko/logo.svg"
                alt="JA兵庫六甲のロゴ"
              />
            </li>
          </ul>
          <p className="main2_sec_p">
            リレイスは信頼関係を大切にしながら、さまざまな企業の開発・運用をサポートしてきました。
            <br />
            伴走するパートナーとして、貴社のご期待にも本気でお応えいたします。
          </p>
        </div>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <h2>
            <span className="main3_inner_h2_first_span">
              <span>OWNERS</span>
              <span>弊社が開発サポートしている企業</span>
            </span>
          </h2>
          <p>リレイスが担当した 過去のプロジェクト支援実績になります</p>
          <div className="main3_wrap">
            <div className="main3_wrap_left">
              <img
                src="https://relace.co.jp/images/app/case-study/mitsui-fudosan/bcp-2-poc-app/main-thumbnail.jpg"
                alt="BCP2.0に向けた新事業コンセプトのPoCアプリ開発のイメージ"
              />
            </div>
            <div className="main3_wrap_right">
              <div className="main3_wrap_right_inner">
                <span>01</span>
                <h3>BCP2.0に向けた新事業コンセプトのPoCアプリ開発</h3>
                <p className="main3_first_p">三井不動産株式会社</p>
                <p className="main3_sec_p">
                  オフィスワーカーの防災スキル向上を目的としたPoC版のWEBアプリを作成。アプリ内で選んだ選択肢によって自分の防災スキルのスコアがわかる診断機能や、防災スキルを向上させることができるコンテンツを提供。
                </p>
              </div>
              <p className="main3_third_p">詳細をみる</p>
            </div>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <h2>
            <span className="main4_span">
              <span className="main4_span_first">PROBLEM＆SOLUTION</span>
              <span className="main4_span_sec">
                リレイスにお寄せいただく
                <br />
                よくあるお悩み
              </span>
            </span>
          </h2>
          <p className="main4_first_p">
            システム開発・新規事業開発に関して、こんなお悩みありませんか？
          </p>
          <div className="main4_wrap">
            <img alt="" src="/head/main1.png" />
            <p>そのお悩みリレイスが解決します！</p>
            <img alt="" src="/head/main2.png" />
          </div>
        </div>
      </div>

      <div className="main5_box">
        <div className="main5_box_inner">
          <h2>
            <span className="main5_inner_h2_first_span">
              <span>STRENGTH</span>
              <span>他社との違い</span>
            </span>
          </h2>
          <p>
            リレイスでは既存の受託システム会社にありがちな問題点に、自省含め向き合い解決することで、以下のような強みがあります。
            <br />
            多くの企業から手放せない・なくてはならないパートナーとして信頼頂いており、ぜひ一度リレイスでの開発をお試し頂ければと思います。
          </p>
          <div className="main5_wrap">
            <img alt="" src="/head/main3.png" />
          </div>
        </div>
      </div>

      <div className="main6_box">
        <div className="main6_box_inner">
          <h2>
            <span className="main6_inner_h2_first_span">
              <span>SERVICE</span>
              <span>リレイスの主な提供サービス</span>
            </span>
          </h2>
          <p>
            お客様の要望に応じて、さまざまなITシステムの開発・活用支援を行っています。
            <br />
            IT活用やデジタルビジネス構築に関して、規模の大小問わず真摯にサポートしますので、型に嵌まらぬ内容含めお気軽にご相談ください。{" "}
          </p>
          <ul>
            <li>
              <p className="main6_li_absoulute">
                <span className="main6_li_p_first_span">01</span>
                <span className="main6_li_p_sec_span">
                  New business development support
                </span>
              </p>
              <div className="main6_li_body">
                <div className="main6_li_body_left">
                  <img
                    src="https://relace.co.jp/images/app/globals/icon-service-01.svg"
                    alt=""
                    class=""
                  />
                </div>
                <div className="main6_li_body_right">
                  <h3>PoC・新規事業開発支援</h3>
                  <p>
                    事業アイディアを素早くプロトタイプに落とし込むことで、より具体的な事業検証を進めることを可能にし、本事業化まで総合的にサポートします。
                  </p>
                </div>
              </div>
            </li>
            <li>
              <p className="main6_li_absoulute">
                <span className="main6_li_p_first_span">02</span>
                <span className="main6_li_p_sec_span">
                  DX support and system development
                </span>
              </p>
              <div className="main6_li_body">
                <div className="main6_li_body_left">
                  <img
                    src="https://relace.co.jp/images/app/globals/icon-service-02.svg"
                    alt=""
                    class=""
                  />
                </div>
                <div className="main6_li_body_right">
                  <h3>DX支援・システム開発</h3>
                  <p>
                    卓越した課題整理力と専門性の高いエンジニアリングで、システム化計画を推し進め、DX(デジタル変革)を実現。大小問わず、親身になってサポート。{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <p className="main6_li_absoulute">
                <span className="main6_li_p_first_span">03</span>
                <span className="main6_li_p_sec_span">
                  System operation / maintenance
                </span>
              </p>
              <div className="main6_li_body">
                <div className="main6_li_body_left">
                  <img
                    src="https://relace.co.jp/images/app/globals/icon-service-03.svg"
                    alt=""
                    class=""
                  />
                </div>
                <div className="main6_li_body_right">
                  <h3>システム運用・保守・リプレース</h3>
                  <p>
                    既存システムの運用・保守もお任せください。古くなったシステムの仕様調査やリプレース作業、開発の引き継ぎも、快く承ります。{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <p className="main6_li_absoulute">
                <span className="main6_li_p_first_span">04</span>
                <span className="main6_li_p_sec_span">
                  Development advisory
                </span>
              </p>
              <div className="main6_li_body">
                <div className="main6_li_body_left">
                  <img
                    src="https://relace.co.jp/images/app/globals/icon-service-04.svg"
                    alt=""
                    class=""
                  />
                </div>
                <div className="main6_li_body_right">
                  <h3>開発アドバイザリー</h3>
                  <p>
                    ユーザー企業の立場に立ち、開発ベンダー選定や、プロジェクト進行等　の相談や橋渡しを、開発のプロフェッショナルがサポートします。{" "}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="main7_box">
        <div className="main7_box_inner">
          <h2>
            <span className="main7_inner_h2_first_span">
              <span>PROCESS</span>
              <span>ご相談からプロジェクト開始までの流れ</span>
            </span>
          </h2>
          <p>
            依頼内容のヒアリングからプロジェクト完了まで、専門チームが責任を持ってしっかりと伴走いたします。{" "}
          </p>

          <ul>
            <li>
              <span className="main7_li_first_span">1</span>
              <div className="main7_li_inner">
                <img
                  src="https://relace.co.jp/images/app/home/icon-process-01.svg"
                  alt="https://relace.co.jp/依頼内容のヒアリングのイメージ"
                  class="w-fit"
                />
              </div>
              <h3>依頼内容のヒアリング</h3>
              <p>
                弊社のPMとエンジニアがお客様のご要望やご予算など詳細についてヒアリングさせていただき、システム化を行う上での見通しや課題整理、技術選定を行います。
              </p>
              <span className="main7_li_sec_span">
                <img
                  src="https://relace.co.jp/images/app/globals/arrow-flow_pc.svg"
                  alt=""
                  class="hidden laptop:block"
                />
              </span>
            </li>{" "}
            <li>
              <span className="main7_li_first_span">2</span>
              <div className="main7_li_inner">
                <img
                  src="https://relace.co.jp/images/app/home/icon-process-02.svg"
                  alt="https://relace.co.jp/依頼内容のヒアリングのイメージ"
                  class="w-fit"
                />
              </div>
              <h3>要求整理・見積もり</h3>
              <p>
                ヒアリング内容を元に、システム内容や大まかな工程など、ご予算に応じた選択肢や具体的なITシステムによる解決策のご提案・見積もりを行います。{" "}
              </p>
              <span className="main7_li_sec_span">
                <img
                  src="https://relace.co.jp/images/app/globals/arrow-flow_pc.svg"
                  alt=""
                  class="hidden laptop:block"
                />
              </span>
            </li>{" "}
            <li>
              <span className="main7_li_first_span">3</span>
              <div className="main7_li_inner">
                <img
                  src="https://relace.co.jp/images/app/home/icon-process-03.svg"
                  alt="https://relace.co.jp/依頼内容のヒアリングのイメージ"
                  class="w-fit"
                />
              </div>
              <h3>ご契約</h3>
              <p>
                契約形態、納期、お支払い方法を決めた後、受注契約と秘密保持契約を取り交わします。お客様側で必要な各種手続き書類に関してもサポートします。
              </p>
              <span className="main7_li_sec_span">
                <img
                  src="https://relace.co.jp/images/app/globals/arrow-flow_pc.svg"
                  alt=""
                  class="hidden laptop:block"
                />
              </span>
            </li>{" "}
            <li>
              <span className="main7_li_first_span">4</span>
              <div className="main7_li_inner">
                <img
                  src="https://relace.co.jp/images/app/home/icon-process-04.svg"
                  alt="https://relace.co.jp/依頼内容のヒアリングのイメージ"
                  class="w-fit"
                />
              </div>
              <h3>プロジェクトスタート</h3>
              <p>
                プロジェクトを進めていく上で必要な準備を行ったのち、システム設計・デザイン・プログラム開発・ITインフラ構築、セキュリティ対策等を進めていきます。{" "}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="main8_box">
        <div className="main8_box_inner">
          <h2>
            <span className="main7_inner_h2_first_span">
              <span>FAQ</span>
              <span>ご相談からよくある質問</span>
            </span>
          </h2>
          <p>
            お客様からいただく質問と回答をまとめました。
            <br />
            他に疑問点やご質問ありましたら、お気軽にお問い合わせください。
          </p>

          <div className="main8_wrap">
            <dl onClick={() => handleToggle(0)}>
              <dt>
                <p>
                  <span>Q1</span>
                  <span>お問合せから見積もりまでどのくらいかかりますか。</span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 0 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 0 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    お問合せ時に入力頂き、初回打ち合わせで聞き出しを行ったあとご要望内容を整理し、1週間程度で概算お見積りのご提示をさせて頂いております。
                    開発プロジェクト進行の見積もりとして、ご要望内容の整理・想定作業・概算工数を整理致します。
                  </span>
                </p>
              </dd>
            </dl>
            <dl onClick={() => handleToggle(1)}>
              <dt>
                <p>
                  <span>Q2</span>
                  <span>開発規模はどの程度まで対応可能ですか。</span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 1 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 1 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    サービスの概念検証を試す小規模開発から本開発まで、要求定義・設計・システム開発・テスト・インフラ保守・セキュリティ診断まで各専門エンジニアがご対応致しますので総合的に対応させて頂いております。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(2)}>
              <dt>
                <p>
                  <span>Q3</span>
                  <span>
                    まだシステム開発の検討段階ですが、具体的な内容が決定していない場合でも相談可能でしょうか。
                  </span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 2 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 2 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    上流工程の段階から相談可能です。
                    要求聞き出しを得意としており、口頭説明でも内容を整理してまとめます。
                    無料問い合わせの範囲では概要のまとめや課題整理を行い、ご希望に応じて上流工程の要求整理段階のサポートも行っております。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(3)}>
              <dt>
                <p>
                  <span>Q4</span>
                  <span>
                    開発を行うことは決まっていますが、社内決済など手続きが多いため資料まとめに困ってます。そのような内容でも相談に乗っていただけますか？
                  </span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 3 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 3 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    企業の各種手続きや進め方には慣れておりますので、社内状況に合わせて柔軟にサポートさせて頂いております。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(4)}>
              <dt>
                <p>
                  <span>Q5</span>
                  <span>
                    すでに開発ベンダーを決めているのですが、開発プロジェクトに不慣れなところもあり、リスクを抑えるために技術調査などのサポートは可能ですか。
                  </span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 4 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 4 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    はい、お客様の不安や悩みを解決するために、まず小さなことからでもご協力致します。開発ベンダー様と円滑にプラスに働く開発となるように、サポートさせて頂いております。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(5)}>
              <dt>
                <p>
                  <span>Q6</span>
                  <span>
                    システム開発はどのようなことに対応可能でしょうか。
                  </span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 5 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 5 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    DX推進に関わる総合的な開発に対応可能ですので、Webサービス開発、アプリ開発、社内業務改善ツール、ローコードツール、Google
                    Workspaceの活用支援などはもちろんのこと、システム要求の初期からの企画、デザイン、ITインフラ保守まで対応可能ですので、何かITに関わる様々な課題に関して、まずはご相談ください。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(6)}>
              <dt>
                <p>
                  <span>Q7</span>
                  <span>開発体制について教えてください。</span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 6 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 6 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    弊社では、ご依頼いただくプロジェクトに応じて開発チームをアサインしております。
                    経験豊富なシステムエンジニアやWEBデザイナーなど幅広いエンジニアが在籍しておりますので、適任者を精査した上でサポート体制を構築しております。
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(7)}>
              <dt>
                <p>
                  <span>Q8</span>
                  <span>セキュリティ体制はどうなっていますか。</span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 7 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 7 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    セキュリティ標準規格であるISMS/27001を取得し、参画エンジニアにはセキュリティ教育を行っております。社内セキュリティ責任者の管理のもと、リモート環境でもセキュリティが保たれる環境を整え、アクセス権限を最小限にすることでお客様の情報を安全に管理しております。
                  </span>
                </p>
              </dd>
            </dl>{" "}
            <dl onClick={() => handleToggle(8)}>
              <dt>
                <p>
                  <span>Q9</span>
                  <span>
                    DX推進や新規事業推進で、チーム立ち上げなど、悩みや問題を抱えており相談できる企業を探しているのですが対応できますか。
                  </span>
                  <span
                    style={{
                      transform: `rotate(${activeIndex === 8 ? 270 : 90}deg)`,
                      transition: "transform 0.3s",
                    }}
                  >
                    {faq}
                  </span>
                </p>
              </dt>
              <dd style={{ display: activeIndex === 8 ? "block" : "none" }}>
                <p>
                  <span>A</span>
                  <span>
                    IT知識がまだ浅い担当者の方にも分かり易くプロジェクト立ち上げ支援を行なっておりますので、まずはお気軽にお問合せください。{" "}
                  </span>
                </p>
              </dd>
            </dl>{" "}
          </div>
        </div>
      </div>

      <div className="main9_box">
        <div className="main9_box_inner">
          <h3>CEO MESSAGE</h3>
          <div className="main9_wrap">
            <p className="main9-w-fit">
              <img
                src="https://relace.co.jp/images/app/globals/ceo-message_pc.svg"
                alt="IT活用により、人と人、仕事と仕事を繋ぎ、手を携える結び目として"
                class="hidden laptop:block"
              />
            </p>
            <p className="main9-w-fit2">
              <span>株式会社リレイス / 代表取締役</span>
              <span>石田 宏実</span>
            </p>
          </div>
          <p className="main9-w-fit3">
            リレイスの社名には、英語で靴の紐を締め直すといった意味を含みます。発展する事業や組織にいずれ訪れる情報処理・システム化の課題に対して、お客様に合った解決策に取り組み、緩んだ紐が再びしっかりと結ばれることで、ビジネスが躓くことなく自信を持って歩み続けられるよう全力でサポートします。ご相談をご検討頂く際に、私たちの思いや強みを知って頂ければと思います。以下よりご覧ください。
          </p>
          <Link to="/message" className="ceo_link">
            トップメッセージへ
          </Link>
        </div>
      </div>

      <div className="main10_box">
        <div className="main10_box_inner">
          <p className="main10_first_p">
            WE WILL DO YOUR BEST TO SUPPORT YOUR CHALLENGES
          </p>
          <h3>
            お客さまの新たな挑戦を
            <br />
            全力でサポートいたします
          </h3>
          <p className="main10_sec_p">
            お客さまに伴走するパートナーとして、ご期待に本気でお応えいたします。
            <br />
            ぜひお気軽にお問い合わせください。
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
