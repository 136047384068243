import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>
            <span className="privacy_inner_h1_span">
              <span>SECURITY POLICY</span>
              <span>
                {" "}
                <font _mstmutation="1">著作権の取り扱い</font>
              </span>
            </span>
          </h1>
          <div className="privacy_laptop">
            <p>
              当サイトに掲載されている全てのコンテンツは、株式会社リレイスが所有しています。{" "}
              <br />
              個人的な目的以外で使用する場合は、書面及びメールにて通知の上、当社の承認が必要となります。
              <br />
              ウェブサイトに転載される場合は、引用元の表記とコンテンツ掲載ページへのリンクを設置することで、当社の承認を得ることなく転載・転用いただけます。
            </p>
          </div>
        </div>
      </div>
      <div className="main10_box">
        <div className="main10_box_inner">
          <p className="main10_first_p">
            WE WILL DO YOUR BEST TO SUPPORT YOUR CHALLENGES
          </p>
          <h3>
            お客さまの新たな挑戦を
            <br />
            全力でサポートいたします
          </h3>
          <p className="main10_sec_p">
            お客さまに伴走するパートナーとして、ご期待に本気でお応えいたします。
            <br />
            ぜひお気軽にお問い合わせください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
