import React from "react";
import Footer from "./Footer";
import "../css/shop.css";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";

function Shop() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="shop_div">
        <div className="shop_div_top">
          <h1>
            Plans for any <span class="css-vykmad">scale</span>
          </h1>
          <p>
            Predictable pricing that scales when you need — no calculator needed
          </p>
        </div>
        <div className="shop_details">
          <div className="shop_details_inner">
            {productData.map((shop, index) => (
              <div className="shop_detail_body" key={index}>
                <p class="shop_name">{shop.name}</p>

                <div className="shop_start_price">
                  <p class="shop_start">starting at</p>
                  <p class="shop_price">
                    $500 <sub class="css-1hwtuxg">/ mo</sub>
                  </p>
                </div>
                <Link
                  to={`/checkout/${shop.id}`}
                  className="to_check_buy"
                  onClick={toTop}
                >
                  Talk to sales →
                </Link>
                <p class="shop_includes">Includes:</p>
                <div className="plan-access-info">
                  {shop.list.map((list, index) => (
                    <div className="plan_includes_internal" key={index}>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="css-6su6fj"
                      >
                        <rect
                          x="12.0117"
                          y="0.583008"
                          width="12.0098"
                          height="12.0098"
                          rx="6.0049"
                          transform="rotate(90 12.0117 0.583008)"
                          fill="#74DFFF"
                          fill-opacity="0.3"
                        ></rect>
                        <g clip-path="url(#clip0_6089_52912)">
                          <path
                            d="M5.10844 9.08973C4.989 9.0897 4.87447 9.03696 4.79003 8.94311L3.43892 7.44188C3.35689 7.34751 3.31149 7.2211 3.31252 7.0899C3.31354 6.95869 3.36091 6.83318 3.44441 6.7404C3.52791 6.64762 3.64087 6.59499 3.75895 6.59385C3.87704 6.59271 3.9908 6.64315 4.07574 6.73431L5.06655 7.8352L7.90522 3.77839C7.97856 3.67355 8.08638 3.60537 8.20496 3.58885C8.32354 3.57233 8.44317 3.60883 8.53753 3.69032C8.63189 3.77181 8.69326 3.89161 8.70812 4.02337C8.72299 4.15513 8.69014 4.28805 8.6168 4.3929L5.46423 8.89657C5.42506 8.9528 5.37556 8.99911 5.31903 9.03241C5.2625 9.06572 5.20022 9.08526 5.13636 9.08973H5.10844Z"
                            fill="#36A7C9"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_6089_52912">
                            <rect
                              width="5.40441"
                              height="6.0049"
                              fill="white"
                              transform="translate(3.30469 3.58594)"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      <p>{list}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="main6_box">
        <div className="main6_box_inner">
          <div className="main6_top">
            <h2>Ready to try it out?</h2>
            <p>
              Over 6 million developers and 50,000 teams test on BrowserStack.
              Join them.
            </p>
          </div>
          <div className="main6_bot">
            <Link to="/shop" className="main6_shop_link" onClick={toTop}>
              View Pricing
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
