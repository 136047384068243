const productData = [
  {
    id: 1,
    name: "Bronze",
    price: "500",
    prePrice: "39",
    list: ["Production License", "Pay-as-you-go", "Ockam support"],
  },
  {
    id: 2,
    name: "Silver",
    price: "2500",
    prePrice: "49",
    list: [
      "Production License",
      "Pay-as-you-go",
      "Ockam support - 24hr response time SLA",
      "Uptime 99% SLA",
      "Dedicated nodes",
    ],
  },
  {
    id: 3,
    name: "Gold",
    price: "10000",
    prePrice: "160",
    list: [
      "Production License",
      "Pay-as-you-go",
      "Ockam support - 4hr response time SLA",
      "Uptime 99.95% SLA",
      "Throughput optimized nodes",
    ],
  },
  {
    id: 4,
    name: "Platinum",
    price: "250000",
    prePrice: "160",
    list: [
      "Production License",
      "Ockam support - 4hr response time SLA",
      "Uptime 99.95% SLA",
      "Throughput optimized nodes",
      "Yearly commit - 20% discount included",
      "Custom Branding / Whitelabel",
      "High Availability Relays",
      "High Availability Credential Authorities",
    ],
  },
];

export default productData;
