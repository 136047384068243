import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Security() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>
            <span className="privacy_inner_h1_span">
              <span>SECURITY POLICY</span>
              <span>
                {" "}
                <font _mstmutation="1">情報セキュリティ方針</font>
              </span>
            </span>
          </h1>
          <div className="privacy_laptop">
            <p>
              株式会社リレイス（以下、当社）は、ITシステム開発技術を元に、お客様の企業の発展に貢献することを理念とし、事業を行っています。当社の事業の中で取り扱う、お客様の情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。漏洩、き損、滅失等のリスクから、情報資産を保護することの重要性を認識した役員や従業員を含めた、情報資産を扱う者が本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践します。{" "}
            </p>
          </div>

          <div className="privacy_body_internal">
            <dl>
              <dt>基本方針</dt>
              <dd>
                <p>
                  情報資産を保護するために、情報セキュリティ方針ならびに、それにまつわる規程類を策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及び、お客様との契約事項を遵守いたします。
                </p>
                <p>
                  情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。
                </p>
                <p>
                  また、その結果に基づき、必要かつ適切なセキュリティ対策を実施いたします。担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。
                </p>
                <p>
                  また、すべての従業者が、情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
                </p>
                <p>
                  情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については、速やかに是正処置を講じます。
                </p>
                <p>
                  情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ、被害を最小限に留めるための対応手順を確立し、有事の際には、速やかに対応するとともに、適切な是正処置を講じます。
                </p>
                <p>
                  また、特に、業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期
                  的に見直しを行うことにより、当社の事業継続を確実にいたします。
                </p>
                <p>
                  基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに、
                  継続的に見直し、改善を行います。
                </p>
              </dd>
            </dl>

            <p className="contact_base">
              2022年4月1日　制定
              <br />
              <br />
              株式会社リレイス代表取締役
              <br />
              石田 宏実
            </p>
          </div>
        </div>
      </div>
      <div className="main10_box">
        <div className="main10_box_inner">
          <p className="main10_first_p">
            WE WILL DO YOUR BEST TO SUPPORT YOUR CHALLENGES
          </p>
          <h3>
            お客さまの新たな挑戦を
            <br />
            全力でサポートいたします
          </h3>
          <p className="main10_sec_p">
            お客さまに伴走するパートナーとして、ご期待に本気でお応えいたします。
            <br />
            ぜひお気軽にお問い合わせください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Security;
