import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_body">
          <div className="foot_to_top">
            <Link to="" onClick={toTop}>
              <span className="foot-text-[15px]">ページトップへ</span>
              <span className="foot-top-block">
                <img
                  src="https://relace.co.jp/images/app/globals/icon-pagetop.svg"
                  alt=""
                />
              </span>
            </Link>
          </div>
          <div className="foot_first_box">
            <img
              src="https://relace.co.jp/images/app/globals/logo.svg"
              alt="株式会社リレイス"
            />
          </div>

          <hr class="foot_hr" />

          <div className="foot_body_bot">
            <div className="foot_body_bot_mid">
              <Link to="/contact" onClick={toTop}>
                お問い合わせ
              </Link>
              <Link to="/privacy" onClick={toTop}>
                個人情報保護について
              </Link>
              <Link to="/terms" onClick={toTop}>
                著作権の取り扱い
              </Link>
              <Link to="/security" onClick={toTop}>
                情報セキュリティ方針
              </Link>
            </div>
          </div>

          <div className="foot_bot">
            <img
              width="164"
              height="64"
              src="https://relace.co.jp/images/app/globals/logo-isms.png"
              alt="ISMS"
              class=""
            />
            <Link to="">ISMS認証登録番号：IS766197</Link>
          </div>
        </div>
        <p class="foot-border-t ">© 2023 RELACE, Inc.</p>
      </div>
    </>
  );
}

export default Footer;
