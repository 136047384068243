import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    emailAdd: "",
    consult: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const errors = {};

    if (!formData.name) errors.name = "この項目は必須です!";
    if (!formData.emailAdd) errors.emailAdd = "この項目は必須です!";
    if (!formData.consult) errors.consult = "この項目は必須です!";
    if (!formData.details) errors.details = "この項目は必須です!";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      name: "",
      company: "",
      emailAdd: "",
      consult: "",
      details: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_box">
        <div className="contact_box_inner">
          <h1>
            <span className="contact_inner_h1_span">
              <span>CONTACT</span>
              <span>
                {" "}
                <font _mstmutation="1">お問い合わせフォーム</font>
              </span>
            </span>
          </h1>
          <div className="contact_laptop">
            <p>
              お仕事のご依頼やご質問は下記のフォームよりお気軽にお問い合わせください。
              <br />
              担当者から２営業日以内を目安にご返信させて頂きます。
            </p>
          </div>
          <p className="contact_red">※は入力必須の項目となります</p>

          <form>
            <div className="contact_form_div">
              <div className="contact_form_detail">
                <label class="contact_block">
                  <font _mstmutation="1">
                    <span _mstmutation="1">※</span>

                    <span>お名前（漢字）</span>
                  </font>
                </label>
                <input
                  type="text"
                  id=" name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="鈴木 太郎"
                  required="required"
                />
                {formErrors.name && (
                  <span className="error-message">{formErrors.name}</span>
                )}
              </div>

              <div className="contact_form_detail">
                <label class="contact_block">
                  <font _mstmutation="1">
                    <span _mstmutation="1"></span>
                    <span>会社名</span>
                  </font>
                </label>
                <input
                  type="text"
                  id=" company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  placeholder="株式会社●●●●"
                />
              </div>

              <div className="contact_form_detail">
                <label class="contact_block">
                  <font _mstmutation="1">
                    <span _mstmutation="1">※</span>

                    <span>メールアドレス</span>
                  </font>
                </label>
                <input
                  type="email"
                  id=" email"
                  name="emailAdd"
                  value={formData.emailAdd}
                  onChange={handleChange}
                  placeholder="number@zape.my"
                />
                {formErrors.emailAdd && (
                  <span className="error-message">{formErrors.emailAdd}</span>
                )}
              </div>

              <div className="contact_form_detail">
                <label class="contact_block">
                  <font _mstmutation="1">
                    <span _mstmutation="1">※</span>

                    <span>ご相談項目</span>
                  </font>
                </label>
                <select
                  id="mySelect"
                  name="consult"
                  value={formData.consult}
                  onChange={handleChange}
                >
                  <option hidden="">選択してください</option>
                  <optgroup label="システム開発" _mstlabel="15725424">
                    <option value="1">
                      Webシステム・サービス開発の相談がしたい
                    </option>
                    <option value="2">業務系システム開発の相談がしたい</option>
                    <option value="3">自動化の相談がしたい</option>
                  </optgroup>
                  <optgroup label="システム改修・保守" _mstlabel="22826427">
                    <option value="4">
                      既存システムのリプレースの相談がしたい
                    </option>
                    <option value="5">
                      システムの保守・運用の相談がしたい
                    </option>
                  </optgroup>
                  <optgroup label="Web制作" _mstlabel="5671055">
                    <option value="6">ホームページ作成の相談がしたい</option>
                    <option value="8">
                      コンテンツ管理システム(CMS)の導入の相談がしたい
                    </option>
                  </optgroup>
                  <optgroup label="ITインフラ" _mstlabel="6842212">
                    <option value="9">
                      ネットワーク設計・構築の相談がしたい
                    </option>
                    <option value="10">サーバー設計・構築の相談がしたい</option>
                  </optgroup>
                  <optgroup label="ITセキュリティ" _mstlabel="11214463">
                    <option value="11">セキュリティ対策の相談がしたい</option>
                    <option value="12">
                      セキュリティ診断・脆弱性評価の相談がしたい
                    </option>
                  </optgroup>
                  <optgroup label="スマホアプリ開発" _mstlabel="21249150">
                    <option value="13">
                      iOS/Androidアプリ開発の相談がしたい
                    </option>
                  </optgroup>
                  <optgroup label="DX支援" _mstlabel="6372431">
                    <option value="14">DX支援に関する相談がしたい</option>
                  </optgroup>
                  <optgroup label="開発コンサルティング" _mstlabel="22874670">
                    <option value="15">
                      現在開発中のシステムの相談がしたい
                    </option>
                    <option value="16">開発ベンダー選定の相談がしたい</option>
                  </optgroup>
                  <optgroup label="業務提携・協業" _mstlabel="20668869">
                    <option value="17">共同開発の相談がしたい</option>
                  </optgroup>
                  <optgroup label="その他" _mstlabel="4777357">
                    <option value="18">上記に該当しない相談がしたい</option>
                  </optgroup>
                </select>
                {formErrors.consult && (
                  <span className="error-message">{formErrors.consult}</span>
                )}
              </div>

              <div className="contact_form_detail">
                <label class="contact_block">
                  <font _mstmutation="1">
                    <span _mstmutation="1">※</span>

                    <span>
                      お問い合わせ内容{" "}
                      <span class="contact_normal">
                        （現状、把握している範囲で構いません）
                      </span>
                    </span>
                  </font>
                </label>
                <textarea
                  rows="12"
                  id="form.content"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                ></textarea>
                {formErrors.details && (
                  <span className="error-message">{formErrors.details}</span>
                )}
              </div>
            </div>
          </form>

          <button
            className="contact_form_submit"
            type="submit"
            onClick={handleFormSubmit}
          >
            <span className="contact_confirm_span">確認画面へ</span>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
