import React, { useState } from "react";
import "../css/checkout.css";
import productData from "../Datas/productData";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Header from "./Header";

const CheckOut = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [value, setValue] = useState({ value: "US", label: "United States" });
  const options = countryList().getData();
  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    email: "",
    zipCode: "",
    vat: "",
    cardHolderName: "",
    cardNumber: "",
    cardExpiration: "",
    cardCvv: "",
  });
  const changeHandler = (value) => {
    setValue(value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // 邮箱格式验证
    if (name === "email") {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      if (!emailPattern.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (!formData.cardNumber.trim())
      errors.cardNumber = "Please enter your card number.";

    if (!formData.cardExpiration.trim())
      errors.cardExpiration = "Please enter the CardExpiration.";

    if (!formData.cardCvv.trim())
      errors.cardCvv = "Please enter the Security Code.";

    if (!formData.cardHolderName.trim())
      errors.cardHolderName = "Please enter the card holder's name";

    if (!formData.email.trim() || !emailPattern.test(formData.email))
      errors.email = "Please enter the email";

    if (!value) errors.country = "Country is required";

    if (!formData.zipCode.trim()) errors.zipCode = "ZipCode is required";

    return errors;
  };

  const handlePlaceOrder = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      navigate("/success");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleCardExpirationChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value.length >= 3) {
      value = value.slice(0, 2) + "/" + value.slice(2, 4);
    }

    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    setFormData((prev) => ({
      ...prev,
      cardExpiration: value,
    }));
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value
        .match(/.{1,4}/g)
        .join(" ")
        .slice(0, 20);
    }

    setFormData((prev) => ({
      ...prev,
      cardNumber: value,
    }));
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <div className="checkout_div">
        <div className="checkout_div_inner">
          <Link to="/shop" class="pricing-link" onClick={toTop}>
            &lt;&nbsp;<u>Back to Pricing</u>
          </Link>

          <div className="check_bill">
            <div className="check_bill_left">
              <h1>Bill Details</h1>
              <div className="checkout-item">
                <div className="checkout-item-left">Email&nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    aria-invalid="false"
                    aria-label=""
                    aria-required="true"
                    class="base-input-field__input-control"
                    id="checkout-item__form-field-email"
                    placeholder="number@zape.my"
                    required=""
                    type="text"
                    autocomplete="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <br />
                  <div className="fill_div">
                    {formErrors.email && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.email}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div class="web-checkout__section-header">Sold To Address</div>

              <div className="checkout-item">
                <div className="checkout-item-left">Country&nbsp;</div>
                <div className="checkout-item-right">
                  <Select
                    options={options}
                    value={value}
                    onChange={changeHandler}
                    placeholder="Select Country"
                    styles={{ height: "38px" }}
                  />
                </div>
              </div>
              <div className="fill_div">
                {formErrors.country && (
                  <>
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      width="20"
                      height="20"
                      class="sHcGI3k"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                      ></path>
                    </svg>
                    <span className="error">{formErrors.country}</span>
                  </>
                )}
              </div>

              <div className="checkout-item">
                <div className="checkout-item-left">Zip/Postalcode&nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    style={{ width: "139.73px", marginRight: "auto" }}
                    class="base-input-field__input-control"
                    id="checkout-item__form-field-zip-code"
                    placeholder="e.g. 90201"
                    required=""
                    maxlength="5"
                    type="text"
                    autocomplete="postal-code"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                  />
                  <div className="fill_div">
                    {formErrors.zipCode && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.zipCode}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div class="web-checkout__section-header">Card Details</div>

              <div className="checkout-item">
                <div className="checkout-item-left">Name on Card&nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    aria-invalid="false"
                    aria-label=""
                    aria-required="true"
                    class="base-input-field__input-control"
                    id="checkout-item__form-field-card-name"
                    placeholder="Your name"
                    required=""
                    type="text"
                    autocomplete="cc-name"
                    name="cardHolderName"
                    maxlength="50"
                    value={formData.cardHolderName}
                    onChange={handleChange}
                  />
                  <br />
                  <div className="fill_div">
                    {formErrors.cardHolderName && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">
                          {formErrors.cardHolderName}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="checkout-item">
                <div className="checkout-item-left">Card Number &nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    id="cardNumber"
                    maxLength="20"
                    pattern="\d{4}\s\d{4}\s\d{4}\s\d{4}"
                    aria-required="true"
                    data-hook="field-input"
                    type="text"
                    name="cardnumber"
                    data-elements-stable-field-name="cardNumber"
                    inputmode="numeric"
                    aria-label="信用卡或借记卡卡号"
                    placeholder="●●●● ●●●● ●●●● ●●●●"
                    value={formData.cardNumber}
                    onChange={handleCardNumberChange}
                  />
                  <br />{" "}
                  <div className="fill_div">
                    {formErrors.cardNumber && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.cardNumber}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="checkout-item">
                <div className="checkout-item-left">Expiry &nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    class="__PrivateStripeElement-input"
                    aria-hidden="true"
                    aria-label=" "
                    autocomplete="false"
                    maxLength="5"
                    placeholder="MM/YY"
                    name="cardExpiration"
                    value={formData.cardExpiration}
                    onChange={handleCardExpirationChange}
                    style={{
                      width: "139.73px",
                      padding: "0 12px",
                      marginRight: "auto",
                    }}
                  />
                  <br />
                  <div className="fill_div">
                    {formErrors.cardExpiration && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">
                          {formErrors.cardExpiration}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="checkout-item">
                <div className="checkout-item-left">CVV &nbsp;</div>
                <div className="checkout-item-right">
                  <input
                    class="__PrivateStripeElement-input"
                    aria-hidden="true"
                    aria-label=" "
                    autocomplete="false"
                    maxLength="3"
                    pattern="\d{3,5}"
                    placeholder="●●●"
                    name="cardCvv"
                    value={formData.cardCvv}
                    onChange={handleChange}
                    style={{
                      width: "139.73px",
                      padding: "0 12px",
                      marginRight: "auto",
                    }}
                  />
                  <br />
                  <div className="fill_div">
                    {formErrors.cardCvv && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.cardCvv}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="check_bill_right">
              <div className="check_bill_right_inner">
                <h2>Order Summary</h2>
                <label>Annually save up to 25%</label>
                <div className="check_summary">
                  <div className="check_summary_top">
                    <h3>Live - Desktop & Mobile</h3>
                  </div>

                  <div className="check_summary_des">
                    <div className="check_summary_des_user">
                      <div class="order-summary__plan__description__users__cost">
                        <span class="order-summary__plan__description__users__cost__currency">
                          <sub>$</sub>
                          {product.price}
                        </span>
                        <span class="order-summary__plan__description__users__cost__spec">
                          /month
                        </span>
                      </div>
                      <div class="order-summary__plan__description__users__period">
                        (billed annually)
                      </div>
                      <div class="order-summary__plan__description__users__count">
                        {product.user}
                      </div>
                    </div>
                    <div class="order-summary__separator"></div>

                    <div className="check_summary_des_bundle">
                      <div class="order-summary__plan__description__bundle__plan">
                        {product.name}
                      </div>
                      <div class="order-summary__plan__description__bundle__spec">
                        <span class="order-summary__plan__description__bundle__spec--bold">
                          ${product.price} x 1 months
                        </span>
                      </div>
                      <div class="order-summary__plan__description__bundle__total">
                        ${product.price * 1}
                      </div>
                    </div>

                    <div className="check_summary_des_bundle">
                      <div class="order-summary__plan__description__bundle__plan">
                        Bug Capture - Team{" "}
                      </div>
                      <div class="order-summary__plan__description__bundle__spec">
                        <span class="order-summary__plan__description__bundle__spec--free">
                          FREE{" "}
                        </span>
                        <span class="order-summary__plan__description__bundle__spec--bold"></span>
                      </div>
                      <div class="order-summary__plan__description__bundle__total">
                        $0
                      </div>
                    </div>

                    <div class="order-summary__separator"></div>

                    <div className="check_summary_des_total">
                      <div class="order-summary__plan__description__total__heading">
                        Total Due
                      </div>
                      <div class="order-summary__plan__description__total__price">
                        ${product.price * 1}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="plan-summary__separator--top"></div>

                <Link
                  to=""
                  className="check_summary_buy"
                  onClick={handlePlaceOrder}
                >
                  Buy Now
                </Link>

                <div class="order-summary__tnc">
                  By placing this order you agree to our{" "}
                  <Link to="/terms" onClick={toTop}>
                    Terms &amp; Conditions
                  </Link>{" "}
                  and auto-renewal.
                </div>

                <div class="card-block">
                  We accept the following cards
                  <div class="card-block__icons">
                    <img
                      src="https://d2ogrdw2mh0rsl.cloudfront.net/production/assets/../react/images/web/discover_logo.svg"
                      alt="discover-logo"
                    />
                    <img
                      src="https://d2ogrdw2mh0rsl.cloudfront.net/production/assets/../react/images/web/amex_logo.svg"
                      alt="amex-logo"
                    />
                    <img
                      src="https://d2ogrdw2mh0rsl.cloudfront.net/production/assets/../react/images/web/visa_logo.svg"
                      alt="visa-logo"
                    />
                    <img
                      src="https://d2ogrdw2mh0rsl.cloudfront.net/production/assets/../react/images/web/maestro_logo.svg"
                      alt="maestro-logo"
                    />
                    <img
                      src="https://d2ogrdw2mh0rsl.cloudfront.net/production/assets/../react/images/web/mastercard_logo.svg"
                      alt="mastercard-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
