import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import { CartProvider } from "./Component/CartContext";
import Privacy from "./Component/Privacy";
import Terms from "./Component/Terms";
import CheckOut from "./Component/CheckOut";
import Success from "./Component/SuccessPay";
import Contact from "./Component/Contact";
import Shop from "./Component/Shop";
import About from "./Component/About";
import Security from "./Component/Security";
import Message from "./Component/Message";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/message" element={<Message />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/checkout/:id" element={<CheckOut />} />
          <Route path="/success" element={<Success />} />
          <Route path="/security" element={<Security />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
