import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner">
          <div className="wrp-navi">
            <div className="fixed_txt_img">
              <img
                src="https://relace.co.jp/images/app/globals/logo.svg"
                alt="株式会社リレイス"
                class="w-full"
              />
            </div>
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  家
                </Link>
              </li>

              <li>
                <Link to="/privacy" onClick={toTop}>
                  個人情報保護について
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
